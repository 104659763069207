
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.App {
  text-align: center;
  background-image: url('../src/assets/background.png');
  width: 100%;
  min-height: 100vh;
  max-width: 2000px;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}
.logo{
  position:absolute;
  right: 50px;
  top: 50px;
}
.logo a img{
  width: 100px;
}

header{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}
header img{
  width: 100%;
}
.socials_container{
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  margin-right: 17%;
  margin-top: -30px;
}
.socials_container img{
  width: 70px;
  height: 70px;
  cursor: pointer;
}
.body{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin-top: 30px;
}
.body img{
  max-width: 450px;
  cursor: pointer;
}
.image_border{
  margin-top: -30px;
}
.clickme{
  position: absolute;
  right: 0;
  margin-right: 100px;
  margin-top: 100px;
}
.manifesto{
  position: absolute;
  left: -50px;
  display: flex;
  flex-direction: column;
  text-align: left;
}
h1, p{
  line-height: 20px;
  font-weight: 600;
  font-size: 1.5rem;
  font-family: 'Poppins', sans-serif;
}
p{
  font-size: 1rem;
}


@media screen and (max-width: 450px) {
  .socials_container img{
    width: 30px;
    height: 30px;
  }
  .logo{
    right: 10px;
    top: 50px;
  }
  .logo a img{
    width: 50px;
  }
header{
  margin-top: 50px;
}
.socials_container{
  
  margin-top: -10px;
}
.body{
  width: 80%;
}
.body img{
  max-width: 250px;
  cursor: pointer;
}
.image_border{
  margin-top: -20px;
}
.clickme{
  position: absolute;
  right: 0;
  margin-right: 0;
  margin-top: 120px;
  width: 100px;
}
.manifesto{
  position: relative;
  padding-bottom: 50px;
  align-items: center;
  justify-content: center;
  left: auto;
 
}
}

@media screen and (max-width: 768px) and (min-width:451px) {
  header{
   margin-top: 50px;
  }
  .logo{
    right: 10px;
    top: 30px;
  }
  .logo a img{
    width: 80px;
  }
  .socials_container{
  
    margin-top: -5px;
  }
  .socials_container img{
    width: 50px;
    height: 50px;
  }
  .body{
    width: 85%;
  }
  .body img{
    max-width: 350px;
    cursor: pointer;
  }
  .image_border{
    margin-top: -20px;
  }
  .clickme{
    position: absolute;
    right: 0;
    margin-right: 0;
    margin-top: 120px;
    width: 150px;
  }
  .manifesto{
    position: relative;
    padding-bottom: 50px;
    align-items: center;
    justify-content: center;
    left: auto;
  }
  
  }

@media screen and (min-width:769px) and (max-width:1220px) {
  .body{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
  }
  .body img{
    max-width: 450px;
    cursor: pointer;
  }
  .image_border{
    margin-top: -25px;
  }
  .clickme{
    position: absolute;
    right: 0;
    margin-top: 150px;
    margin-right: 30px;
    width: 200px;
  }    
}
